export default {
    menu:{
        about:"About Us",
        customize:"Custom Development",
        website:"Website Development",
        app:"APP Development",
        wechat:"WeChat Development",
        store:"Online Store",
        it:"IT Support Services",
        contact:"Contact Us",
        tvp:"TVP",
        funding:"Apply Funding",
        DTSPP:"DTSPP",
        quotation:"Get a Quote",
        consultation:"Chat Now",
        cloud:"Cloud Backup",
    },
    index:{
        welcome:"Welcome",
        come:"to",
        name:"CHINA SKY TECHNOLOGY SYSTEM LIMITED",
        more:"More Detail",
        customize0:"Customize and develop your unique system",
        customize1:"Fixed Assets System",
        customize2:"Loan System",
        customize3:"QC Inspection System",
        customize4:"Tuition Center System",
        customize5:"Wholesale System",
        customize6:"CRM System",
        customize7:"Mini Storage System",
        customize8:"Online Store",
        customize9:"ERP System",
        customize10:"Appointment Booking System",
        customize11:"Queue System",
        customize12:"MRP System",
        customize13:"Trade System",
        customize14:"Inventory System",
        customize15:"OA System",
        customize16:"Renovation Platform",
        customize17:"Other",
        website1:"Website Development",
        website2:"Custom Website Development involves creating a unique website solution that is personalized, flexible, and scalable to meet the client's specific requirements.",
        app0:"Custom App Development involves creating a unique app tailored to meet the specific requirements of the client.",
        app1:"Appointment Booking App",
        app2:"Warehouse APP",
        app3:"Tutoring Center APP",
        app4:"MRP APP",
        app5:"Mini Storage APP",
        app6:"CRM APP",
        app7:"Membership APP",
        app8:"OA APP",
        app9:"Online StoreAPP ",
        wechat1:"Custom WeChat Development",
        wechat2:"Unlock Unlimited Possibilities with WeChat! We offer personalized interactions and business expansion through WeChat Official Accounts, Mini Programs, and WeChat Work.",
        mall1:"Multi-Currency Support",
        mall2:"Multi-Language Support",
        mall3:"Gift Voucher and Discount Functionality",
        mall4:"Shopping Cart",
        mall5:"Product Search",
        mall6:"Online Payment Functionality",
        mall7:"Membership System",
        mall8:"Order History Lookup",
        it1:"We are a leading IT support service provider, offering comprehensive technical support and solutions for your needs.",
        it2:"Our team delivers personalized IT support services tailored to your needs, ensuring the smooth operation of your systems and devices.",
        it3:"We offer worry-free IT support and maintenance services, allowing you to focus on business development.",
        thank:"Thank you for your enquiry, we will contact you as soon as possible ",
    },
    footer:{
            company1:"CHINA SKY TECHNOLOGY SYSTEM LIMITED",
            company2:"CHINA SKY TECHNOLOGY SYSTEM (SZ) LIMITED",
            address:"Address",
            address1:"Room 13A, 15 Floor, One Midtown, No. 11 Hoi Shing Road, Tsuen Wan, Hong Kong",
            address2:"Room 17A11, Block C, Lushan Building, No. 8 Jiangbei Road, Luohu District, Shenzhen, China",
            mail:"E-mail",
            // mail1:"info@chinaskynet.net",
            tel1:"(852) 24902300",
            tel2:"(0755) 25100512",
            map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.5936695248754!2d114.10767737979234!3d22.37254927203976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f8e5707631e1%3A0x2ef537658d4502e8!2sOne+Midtown%2C+11+Hoi+Shing+Rd%2C+Tsuen+Wan!5e0!3m2!1szh-CN!2shk!4v1547459323144"
    },
    form:{
        placeholderName: 'Please enter your name',
            placeholderTel: 'Please enter your phone number',
            placeholderEmail: 'Please enter your email address',
            placeholderText: 'Please enter your message',
            placeholderEmail2: 'Please enter a valid email address.',
            name: "Name：",
            mail: "E-mail：",
            tel: "Phone：",
            message: "Message：",
            submit: "Submit",
            successText: 'Verification successful!',
            failText: 'Verification failed. Please retry.',
            sliderText: "To swipe right.",
            titles: "Message",
            titleText:"Have questions? Contact us for answers.",
            tips:"indicates required fields.",
            online:"Rent an online store"
    },
    project: {
            caseTit: 'Our cases',
            JiaZaiGD: 'Show More',
            ChaKan: 'See More'
    },
    page:{
            quotation: "Free Real-Time Quote",
            getQuotation: "For more system development, please contact us",
            customizeName0: "System Development",
            customizeName1: "Product Development",
            customizeIntro1: "China Sky Technology: Your one-stop solution for computer software design outsourcing. We provide comprehensive services from requirement analysis to system support and maintenance.",
            customizeName2: "Professional Team",
            customizeIntro2: "We carefully examine your goals and requirements, providing you with the most suitable implementation plan.",
            customizeName3: "Existing System Support and Upgrades",
            customizeIntro3: "We offer support and upgrades for existing systems, migrating them to emerging technologies while preserving the existing data.",
            customizeName4: "Debugging Services",
            customizeIntro4: "In addition to offering remote debugging services, our team will communicate with you as if we were right there with you, no matter the distance.",
            customizeName5: "Support and Maintenance",
            customizeIntro5: "Prompt communication with our software engineers via phone or email. Quick response and error fixing.",
            customizeName6: "Code Review Services",
            customizeIntro6: "Code review services ensure code reliability, maintainability, efficiency, and security. We offer tailored IT support and solutions for businesses of all sizes. Contact us for more information and a free quote on our Help Desk and other IT services. Let us help you overcome IT challenges and focus on your core business.",
            customizeTitle: "Customize a system tailored specifically to your needs.",
            customizeName7: "Loan System",
            customizeName71: "Customer Data Module",
            customizeName72: "Loan Management Module",
            customizeName73: "Repayment Management Module",
            customizeName74: "Customer Information Module",
            customizeName8: "Trade System",
            customizeName81: "Sales Module",
            customizeName82: "Procurement Module",
            customizeName83: "Inventory Module",
            customizeName84: "Finance Module",
            customizeName9: "MRP Production Management System",
            customizeName91: "Sales Module",
            customizeName92: "Production Module",
            customizeName93: "Procurement Module",
            customizeName94: "Quality Inspection Module",
            customizeName95: "Inventory Module",
            customizeName10: "Wholesale System",
            customizeName101: "Set up retailers",
            customizeName102: "Set up various fruit names and prices",
            customizeName103: "Generate orders",
            customizeName104: "Consolidate total order quantities",
            customizeName105: "Generate shipping orders and invoices",
            customizeName11: "Renovation Platform",
            customizeName111: "User Registration and Login",
            customizeName112: "Set Available Services",
            customizeName113: "Craftsman Registration and Login",
            customizeName114: "Administrator Approval of Craftsman",
            customizeName115: "User Placing Orders",
            customizeName116: "Craftsman Accepting Orders",
            customizeName117: "Payment after Completion and User Rating of Craftsman",
            customizeName12: "Fixed Assets System",
            customizeName121: "User Registration and Login",
            customizeName122: "Set Available Services",
            customizeName123: "Craftsman Registration and Login",
            customizeName124: "Administrator Approval of Craftsman",
            customizeName125: "User Placing Orders",
            customizeName126: "Craftsman Accepting Orders",
            customizeName127: "Payment after Completion and User Rating of Craftsman",
            customizeName13: "Tutoring Center System",
            customizeName131: "User Registration and Login",
            customizeName132: "Set Free Trial Period",
            customizeName133: "Division for Primary and Secondary School Students",
            customizeName134: "Primary School Students Can Upload Daily Homework",
            customizeName135: "Instant Q&A Function - Online Homework Help",
            customizeName136: "Provision of Supplementary Exercises in Various Subjects",
            customizeName137: "Membership Purchase and Renewal Service",
            customizeName14: "Self-Storage System",
            customizeName141: "Online Payment",
            customizeName142: "User Ordering and Reservation of Storage Units",
            customizeName143: "User Photographs Items",
            customizeName144: "Set Different Charges for Each Type",
            customizeName145: "Admin Can View Detailed Information of Each Order and Payment Status",
            customizeName146: "Delivery Personnel Receive Delivery Orders and Contact Information",
            customizeName147: "System Daily Reminder for Expiring Customer Orders",
            customizeName148: "User Registration and Login",
            customizeName15: "Mini Storage System",
            customizeName151: "Create Quotations and Save Different Versions of Quotations",
            customizeName152: "Generate Invoices and Save Confirmed Quotations from Customers",
            customizeName153: "Generate Accounts Receivable and Accounts Payable",
            customizeName154: "Employee Clock-in for Attendance and Monthly Salary Calculation",
            customizeName155: "Leave Application, View Remaining Annual Leave and Approval Status",
            customizeName156: "Expense Reimbursement Application, View Approval Status",
            customizeName16: "QC Inspection System",
            customizeName161: "Extract required inspection data from the SAP system and import it into the app",
            customizeName162: "Administrators assign inspection orders to relevant inspector accounts in the app",
            customizeName163: "Inspectors log in to view the inspection orders they are responsible for, including inspection dates and factory addresses",
            customizeName164: "Inspectors perform inspections, take photos, and enter inspection data. After inspection, factory representatives can sign in to the app, and the system generates an inspection report",
            customizeName165: "Users can send the inspection report via email",
            quotationNeed: "Get a Quote",
            bottom:"Fast Quote, Quality Solutions",
            website1:"Responsive Website vs. Traditional Website Comparison",
            website2:"Responsive websites and traditional websites are two different approaches to website design, and they have some significant differences in terms of design and usage. Responsive websites, on the other hand, utilize flexible layouts that automatically adjust the appearance and layout of the website based on the screen size and resolution of the accessing device.",
            websiteName3:"Responsive websites",
            websiteTxt311:"Design Approach：",
            websiteTxt312:"Responsive websites, on the other hand, utilize fluid layouts that can automatically adjust the appearance and layout of the website based on the screen size and resolution of the accessing device.",
            websiteTxt321:"User Experience：",
            websiteTxt322:"Responsive websites offer a better user experience as they can adapt to various devices, including desktop computers, tablets, and mobile phones. Regardless of the device users use to access the website, they can enjoy a seamless browsing experience.",
            websiteTxt331:"Development costs：",
            websiteTxt332:"Responsive websites typically require additional development work as they need to adjust layouts and designs based on different screen sizes and resolutions. This means that developing a responsive website may require more time and resources.",
            websiteTxt341:"SEO Benefits：",
            websiteTxt342:"Due to the provision of a unified URL and HTML code, responsive websites are more favorable for search engine optimization (SEO).",
            websiteName4:"Traditional websites",
            websiteTxt41:"Traditional websites are typically designed for desktop computers, using fixed sizes and layouts.",
            websiteTxt42:"Traditional websites may require horizontal scrolling or zooming on smaller screens to view content, resulting in a less convenient user experience compared to responsive websites.",
            websiteTxt43:"Traditional websites only require designing a single fixed version, which makes the development process relatively simpler compared to responsive websites.",
            websiteTxt44:"If a traditional website has multiple versions (such as desktop and mobile versions), additional SEO efforts may be required to ensure that each version achieves good rankings.",
            websiteName5:"Multi-device display offers several advantages",
            websiteTxt5:"Provide a good cross-device experience. Improve website accessibility. Increase user engagement and interactivity. good for search engine optimization",
            appName1:"Native APP",
            appTxt1:"Native apps are applications that are developed specifically for a particular platform, such as iOS or Android, using the platform's native programming languages, such as Objective-C/Swift for iOS or Java/Kotlin for Android. They are designed to fully leverage the platform's features and performance, providing the best user experience. Native apps typically require separate development and maintenance for each platform. Overall, native apps offer the best user experience but require platform-specific development.",
            appName2:"Hybrid APP",
            appTxt2:"A hybrid app is an application developed using web technologies such as HTML, CSS, and JavaScript, and then packaged as a native app using frameworks like React Native or Ionic. Hybrid apps can run on multiple platforms and reuse code, thereby saving development costs and time. However, they may not fully match the performance and functionality of native apps. In general, hybrid apps offer the advantage of cross-platform compatibility but may lack the performance of native apps.",
            appName3:"Web APP",
            appTxt3:"A Web app is an application developed using web technologies such as HTML, CSS, and JavaScript, and can run in a web browser. Web apps do not require installation; users can access them directly through a browser. They offer the advantages of cross-platform compatibility and real-time updates. However, they may be limited by network connectivity and browser capabilities. In general, Web apps have the advantages of cross-platform compatibility and real-time updates, but may have limitations in terms of functionality and performance.",
            wechatName1:"WeChat Mini APP",
            wechatTxt1:"WeChat Mini Program is a lightweight application that runs within the WeChat platform, allowing users to access and use various functionalities directly within the app without additional installations. It provides a wide range of features, including online shopping, social media, lifestyle services, and more. WeChat Mini Programs are developed using dedicated frameworks for Mini Programs, such as the WeChat Native Framework, Taro, or uni-app, which enable cross-platform compatibility and deliver good user experience and performance. In summary, WeChat Mini Program is a lightweight application running within the WeChat platform.",
            wechatName2:"WeChat Official Account",
            wechatTxt2:"WeChat Official Account, also known as WeChat Public Account, is an official account based on the WeChat platform where users can subscribe and receive messages, articles, images, videos, and more published by the account. They are typically owned and operated by businesses, organizations, celebrities, etc., and are used for brand promotion, information dissemination, customer interaction, and other purposes. WeChat Official Accounts can be categorized into Subscription Accounts and Service Accounts, with different functionalities and permissions. In general, WeChat Official Accounts are official accounts used for information dissemination and brand promotion.",
            wechatName3:"WeChat Work",
            wechatTxt3:"WeChat Work is an enterprise communication and collaboration platform designed specifically for internal use within businesses. It serves as an enterprise version of WeChat, providing features such as internal communication, organizational structure, workflow management, and application integration. Enterprise WeChat aims to enhance internal communication and collaboration within organizations. It also supports developing and customizing enterprise applications, allowing businesses to extend the functionality according to their specific needs. In summary, Enterprise WeChat is an internal communication and collaboration platform for businesses.",
            mall1:"Explore the unbounded shopping experience: the online mall takes you into a new world of e-commerce",
            mall111:"Product management",
            mall2:"Shopping cart function",
            mall3:"Collection",
            mall4:"Marketing activities",
            mall5:"Order management",
            mall6:"Login/Sign up",
            mall7:"Member Centre",
            mall8:"After-sale refund",
            mall9:"Rental Limited Time Offer",
            mall10:"Website+APP（iOS+Android）",
            mall11:"Year",
            mall12:"Purchase",
            mall13:"Purchase includes",
            mall14:"3 language versions (Traditional Chinese, Simplified Chinese, English)",
            mall15:"Including 10G of cloud space for one year and 50G of traffic per month",
            mall16:"Preferential price - HKD 2,900.00 will be charged from the second year (cloud space and maintenance fee - including the same service that is 10G space and monthly 50G traffic, system debugging service and app version update)",
            mall17:"From the third year, the regular price is HKD 5,800.00.(cloud space and maintenance fee - including the same service that is 10G space and monthly 50G traffic, system debugging service and app version update)",
            mall19:"Only access up to 4 payment methods, more than 4 will charge additional fees",
            it0:require('@/assets/img/IT_pic01-en.png'),
            it1:"Service scope covers both China and Hong Kong",
            itName1:"Data backup and protection",
            itTxt1:"Data backup and protection involve duplicating data to another location or storage media to prevent data loss, damage, or unauthorized access. It includes regularly backing up data, establishing backup strategies, encrypting data, monitoring and testing the effectiveness of backup systems. These measures ensure the reliability and security of data and provide preparedness for data disasters.",
            itName2:"Desktop maintenance",
            itTxt2:"Desktop maintenance is the activity of managing and supporting the computer desktop environment, including system monitoring, software installation, virus protection, data backup, and user support. It aims to provide a stable, secure, and efficient desktop working environment, ensuring smooth operations and data security for users.",
            itName3:"Computer Technical Support",
            itTxt3:"Computer technical support is a service that provides assistance and support in resolving computer issues, including software installation, troubleshooting, virus protection, network connectivity, hardware repairs, and user support, among others.",
            itTxt31:"Typically, computer technical support can be provided through various methods, including remote support, telephone support, on-site support, and online resources.",
            itTxt32:"In short, computer technical support is a service that provides solutions to computer and technology-related issues, helping users effectively use and maintain their systems. It is a crucial component in ensuring smooth operations and maximizing the benefits of computer technology.",
            itName4:"IT maintenance",
            itTxt4:"IT maintenance refers to a series of activities aimed at managing, maintaining, and supporting the information technology (IT) infrastructure and systems. It is intended to ensure the stable operation of IT resources and provide an efficient, secure, and reliable IT environment.",
            itTxt41:"The goal of IT maintenance is to ensure the smooth operation of IT systems and resources, minimizing the risk of system failures and data loss, and improving organizational productivity and efficiency. It is one of the critical functions of the IT department in businesses and organizations, contributing to the availability, security, and reliability of IT resources.",
            itName5:"Network management",
            itTxt5:"Network management refers to the activities of effectively managing and monitoring computer network infrastructure. It encompasses the management of network devices, connections, resources, and security aspects.",
            itTxt51:"The primary goals of network management are to ensure network availability, performance, and security to meet the needs of the organization and support business operations.",
            itTxt52:"Ensuring smooth and reliable network operation is a critical aspect of network management. It helps improve network performance, reduce the risk of failures, and provide a good user experience. Organizations and businesses can utilize network management to effectively manage and maintain their network infrastructure to support seamless business operations.",
            itName6:"Server management",
            itTxt6:"Server management refers to a series of activities that involve the effective management and monitoring of server hardware and software. It includes server configuration, installation, maintenance, and monitoring to ensure the smooth operation and efficient performance of servers.",
            itTxt61:"The main goal of server management is to ensure the availability, security, and performance of servers to meet the organization's needs and support business operations.",
            itTxt62:"Server management is a crucial aspect in ensuring smooth and reliable server operations. It helps improve server performance, minimize the risk of failures, and ensure the integrity and availability of data. Organizations and businesses can rely on effective server management to maintain the stability of their business systems and meet evolving requirements.",
            itReason:"Why choose CHINA SKY",
            itReason1:"Professional technology: Possess rich IT technical knowledge and experience.",
            itReason2:"Personalized service： Provide tailor-made solutions, focusing on the unique needs of customers.",
            itReason3:"Timely Response: Respond quickly and provide solutions to reduce downtime.",
            itReason4:"Comprehensive solution: provide a full range of IT support services, saving time and effort.",
            itReason5:"Trust and reputation: With a good reputation and reputation, we have established long-term cooperative relations with many customers.",
            rentBtn: "Rent",
            //
            look: "View Case",
            addStore01: "CHINA SKY TECHNOLOGY Anniversary Celebration",
            addStore02: "Online Store Rental",
            addStore03: "Explosive Price!!",
            addStore04: "HKD",
            addStore05: "Year",
            addStore06: "Includes Website + APP Store (iOS+Android)",
            addStore07: "Original Price: HKD 22,800.00/year",
            addStore08: "Rent Now",
            addStore09: "Online Store Rental Service",
            addStore10: "Our online store rental service eliminates the expensive cost of independent development, saves your development time, allows your business to start quickly, and the service includes websites and apps. It supports various types of product categories and provides a one-stop solution for creating your online store.",
            addStore11: "Membership System",
            addStore12: "Product System",
            addStore13: "Shopping Cart",
            addStore14: "Order System",
            addStore15: "Online Payment",
            addStore16: "Report Export Function",
            addStore17: "Discount Function",
            addStore18: "Points Deduction for Cash Function",
            addStore19: "Supports Traditional Chinese, Simplified Chinese, and English",
            addStore20: "1 year of cloud storage space (10GB) and 50GB monthly traffic",
            addStore21: require('@/assets/img/ad-en.png'),
    },
    video:{
            url:require('@/assets/img/video-en.mp4'),
            subtitles1:"Nowadays, the evolutionary speed of the online world is amazing. To make customers feel more friendly and convenient, a complete IT system and network platform is a must.",
            subtitles2:"China Sky Technology System Limited was established at Hong Kong in 2004 and Shenzhen branch was opened in the same year.",
            subtitles3:"We have been providing IT outsourcing services to companies in Hong Kong and the Mainland, including fixed inspections, network management, network security, and VPN solutions.",
            subtitles4:"Since we have professional technicians in both mainland of China and Hong Kong, we can provide the most immediate and appropriate support to customers regardless of the IT problems encountered in Hong Kong or in the Mainland.",
            subtitles5:"In recent years, network marketing has provided enterprises with brand new business opportunities. The related programs and services of social networking platforms have become powerful tools for companies. ",
            subtitles6:"China Sky Technology has mastered comprehensive related technologies for each project from design, development and online promotion. It also has a strong development and marketing team to provide customers with various types of marketing strategies and corresponding development services. We help customers in the most cost efficient way.",
            subtitles7:"In order to cooperate with the development of the Dawan District and the Belt and Road, both domestic and foreign companies need to further optimize business processes and costs in order to expand business opportunities. ",
            subtitles8:"We have the experience of network consulting in China and abroad, ",
            subtitles9:"and can design the most suitable network marketing solutions for our customers and make their business more successful.",
    },
    net:{
        servesBigTitle0:"Our Service",
        servesBigTitle1:"Network maintenance",
        servesTitle1:"Network Monitoring",
        servesContent1:"Monitoring network traffic, connectivity status, and performance to ensure the network operates smoothly.",
        servesTitle2:"Security Checks",
        servesContent2:"Regular security assessments, including firewall configurations, intrusion detection systems, and vulnerability assessments, to prevent unauthorized access or attacks.",
        servesTitle3:"Data Backup and Recovery",
        servesContent3:"Establishing regular network data backup plans to ensure crucial data can be swiftly recovered when needed.",
        servesTitle4:"Network Equipment Updates",
        servesContent4:"Regular updates of router, switches, and other network device software, along with hardware performance checks, to ensure efficient network operations.",
        servesTitle5:"Network Performance Optimization",
        servesContent5:"Optimizing network settings, adjusting configurations of routers and switches to ensure efficient data transmission and low latency.",
        servesTitle6:"User Account Management",
        servesContent6:"Managing user accounts, including creating, deleting, and modifying account permissions, ensuring authorized users can access network resources.",
        servesTitle7:"Emergency Response Plans",
        servesContent7:"Developing plans to respond to network failures and security incidents, ensuring prompt responses and resolutions in case of network issues.",
        servesTitle8:"Training and Awareness",
        servesContent8:"Providing security training for users to enhance their awareness, reducing the risks of network threats.",
        servesBigTitle2:"Computer maintenance",
        servesTitle21:"Hardware Inspection",
        servesContent21:"Regular checks of computer hardware components to ensure proper cooling and performance.",
        servesTitle22:"Software Updates and Security Patches",
        servesContent22:"Installation of the latest operating system, application software, and antivirus updates, along with timely installation of security patches.",
        servesTitle23:"Virus and Malware Scans",
        servesContent23:"Regular scans for viruses and malware to ensure system security, removing any potential threats.",
        servesTitle24:"Data Backup and Recovery",
        servesContent24:"Establishing regular data backup plans to ensure important data can be quickly recovered when needed.",
        servesTitle25:"Performance Optimization",
        servesContent25:"Optimizing system settings, enhancing computer performance, and clearing unnecessary files and applications to free up disk space.",
        servesTitle26:"Hardware Replacement and Upgrades",
        servesContent26:"Periodic evaluation of hardware performance, upgrading or replacing hardware as needed to keep up with business requirements.",
        servesTitle27:"User Support and Issue Resolution",
        servesContent27:"Providing user support services, resolving problems users encounter while using computers, ensuring efficient system usage.",
        reason0:"Why need us",
        reason1:"computer systems are the backbone of business operations, handling data storage, processing, and communication functions. Regular maintenance ensures stable system performance, reducing the chances of breakdowns and ensuring business continuity. ",
        reason2:"With the increasing cybersecurity threats, maintenance services can identify system vulnerabilities, install the latest security patches, and enhance the system's resistance against attacks, safeguarding critical data from breaches. ",
        reason3:"Regular maintenance can prolong the lifespan of computer equipment, thereby reducing operational costs for businesses. In summary, computer maintenance and servicing are vital investments for businesses to ensure smooth operations, data security, and cost-effectiveness.",
        set0:"Service package",
        set1:"Remote Service",
        set2:"Express door-to-door service (per trip)",
        set3:"Monthly service",
        set4:"Annual fee service",
        set5:"Remotely",
        set6:"Call up",
        set7:"Go to the scene",
        set8:"Periodic inspection",
        set9:"Unlimited times",
        set10:"1 time",
        set11:"1 time/month",
    },
    tvp:{
        tvp00: "TVP Technology Voucher Program Service",
        tvp01: "Up to HKD 600,000 TVP Technology Voucher Application: Eligibility, Approval Time, and Notes",
        tvp02: "Maximum Funding of HKD 600,000",
        tvp03: "The Technology Voucher Program (TVP), launched in November 2016, aims to support local businesses in upgrading and transforming their business processes through technology. TVP accepts applications throughout the year, with a maximum of six projects approved, totaling up to HKD 600,000. The funding is allocated on a project basis, with a funding ratio of 3:1 (government:company), allowing the government to fund up to 75% of the project expenses, while the remaining 25% is borne by the company. It is important to note that only one project application is accepted at a time, and each project must be completed within one year.",
        tvp04: "What companies are eligible to apply for TVP Technology Vouchers?",
        tvp05: "The Technology Voucher Program aims to support local businesses in enhancing their business productivity, optimizing business processes, and even facilitating digital transformation to enhance competitiveness through the introduction of technological solutions. In other words, the program is suitable for business owners who intend to digitize their operations. The program covers a wide range of industries, attracting applications from different sectors. Some popular industries that apply for TVP include logistics, retail, catering, and accounting. The following are the basic eligibility criteria for TVP:",
        tvp06: "1. Not a listed company in Hong Kong or a subsidiary of a government-funded organization;",
        tvp07: "2. Have substantive business operations in Hong Kong at the time of application, and the business must be related to the proposed project; and",
        tvp08: "3. The applying company meets one of the following requirements:",
        tvp09: "Registered under the Business Registration Ordinance (Chapter 310) in Hong Kong; or",
        tvp10: "Incorporated as a company under the Companies Ordinance (Chapter 622) in Hong Kong.",
        tvp11: "A statutory body established in Hong Kong under the relevant ordinances.",
        tvp12: "Examples of Technological Applications in Different Industries",
        tvp13: "Technological applications are not limited to small and medium-sized enterprises (SMEs), and the eligibility of projects can vary. The table below provides examples of technological applications in different industries and popular projects for SMEs applying for the TVP Technology Voucher Program as a reference:",
        tvp14: "Industry",
        tvp15: "Examples of Technological Applications",
        tvp16: "Logistics",
        tvp17: "Implementing an order tracking system for employees to update shipment records and for customers to check order status.",
        tvp18: "Retail",
        tvp19: "Introducing a CRM system to collect and analyze customer big data.",
        tvp20: "Catering",
        tvp21: "Using POS systems for customers to scan QR codes and place orders through their mobile phones.",
        tvp22: "Accounting",
        tvp23: "Applying cloud-based/AI software to handle repetitive computational tasks, such as payroll calculations, while maintaining an overview of the company's financial status.",
        tvp24: "Popular TVP Technology Voucher Projects for SMEs:",
        tvp25: "Online stores/APPs/e-commerce platforms",
        tvp26: "CRM (Customer Relationship Management) system",
        tvp27: "ERP (Enterprise Resource Planning) system",
        tvp28: "POS (Point of Sale) retail sales system",
        tvp29: "HRM (Human Resource Management) system",
        tvp30: "Chatbot",
        tvp31: "Cloud solutions",
        tvp32: "Database management",
        tvp33: "WMS (Warehouse Management System)",
        tvp34: "Overview of TVP Technology Voucher Application Procedure",
        tvp35: "1. Obtain quotations and signed Declaration of Honesty and Non-Collusion in Quotation/Tender Confirmation from service providers.",
        tvp36: "2. Submit the application and required documents through the Technology Voucher Program Management System.",
        tvp37: "Application website:",
        tvp38: "Technology Voucher Program Management System:",
        tvp39: "Sample application form:",
        tvp40: "Technology Voucher Application Guide (refer to Annex 3 for required documents):",
        tvp41: "3. Conduct internal review",
        tvp42: "General review criteria",
        tvp43: "Relevance of the project to the applicant's business",
        tvp44: "Reasonableness of the budget and implementation details",
        tvp45: "Any adverse records of the technical consultant/service provider",
        tvp46: "4. Successful applicants sign the funding agreement with the Innovation and Technology Commission.",
        tvp47: "5. Apply for the first installment of funding as needed.",
        tvp470: "The maximum first installment can reach 25% of the approved funding amount.",
        tvp48: "6. Implement the project and complete it within one year, submitting the final report and audited accounts.",
        tvp49: "7. The Innovation and Technology Commission notifies the applicants of the review results in writing.",
        tvp50: "8. Disburse the funding to the approved applicants.",
        tvp51: "How to apply for the first installment of TVP Technology Voucher?",
        tvp52: "Many enterprises, when introducing innovative technology, hope to have funds for initial expenses and startup capital. In fact, the TVP Technology Voucher can also be used to apply for the first installment of funding, with a maximum of 25% of the approved project funding. The following are the procedures for applying for the first installment of funding:",
        tvp53: "1. Submit the application for the first installment of funding after signing the funding agreement.",
        tvp54: "2. Provide a dedicated company bank account for receiving the Technology Voucher, which can only be used for handling the income and expenses related to the project.",
        tvp55: "3. Deposit the specified amount into the bank account (according to the 3:1 distribution principle, deposit the same percentage of funds).",
        tvp56: "4. Disburse the first installment of funding to the approved applicants.",
        tvp57: "Methods of disbursing the first installment of funding",
        tvp58: "The maximum limit for the first installment of funding is 25% of the approved project funding, and it will be disbursed only after the applicant has confirmed the deposit of the specified funds into the company bank account.",
        tvp59: "Example:",
        tvp60: "Suppose the approved project funding is HK$400,000, of which HK$300,000 is funded by the government, and the remaining HK$100,000 is borne by the applicant. The applicant needs to deposit the same percentage of funds according to the 3:1 distribution principle, which is HK$25,000 (25% of HK$100,000), and then provide proof of deposit. After confirmation, they will receive the first installment of funding, which is HK$75,000 (25% of HK$300,000).",
        tvp61: "Application Time: When is the earliest approval for TVP?",
        tvp62: "After receiving the application, the Innovation and Technology Commission will assess the applicant's eligibility and evaluate the relevance of the business, reasonableness of the budget, and details of the project, as well as check the past records of the technical consultant or service provider. The actual approval time depends on factors such as the number of applications received at that time, the complexity of individual applications, and whether the submitted data is complete and clear. During the evaluation period, the authorities may request clarification or supplementary data from the applicants, who must submit the documents within one month after receiving the relevant request.",
        tvp63: "Regarding the disbursement of the final installment of funding, applicants must submit the following documents to the Innovation and Technology Commission through the Technology Voucher Program Management System within two months after the completion of the project:",
        tvp64: "Click here to access the Technology Voucher Program Management System:",
        tvp65: "1. Final project report",
        tvp66: "2. Proof of project achievements (such as consultant's report, delivery receipts, screenshots of software systems, photos of equipment hardware, records of conducted training such as photos and training materials, etc.)",
        tvp67: "3. Original or copy of payment invoices and corresponding receipts for each expenditure item, with the payee's name matching the applicant's name.",
        tvp68: "If the approved funding exceeds HK$50,000, the applicant must submit expenditure statements audited by a qualified independent auditor to the Innovation and Technology Commission. For funding below HK$50,000, the applicant only needs to submit the final expenditure statement and confirm its accuracy.",
        tvp69: "Frequently Asked Questions about TVP Technology Voucher",
        tvp70: "Do I have to wait until the funding application is approved before starting the project?",
        tvp71: "No, you can start the project as early as the day after submitting the application.",
        tvp72: "Can I apply for TVP funding for projects that have already started?",
        tvp73: "Yes, but applicants must submit the completed 'Declaration and Commitment' form to the Innovation and Technology Commission no later than five working days after the actual project start date. You can visit",
        tvp74: "to download the form.",
        tvp75: "If I do not have actual business in Hong Kong or the majority of my business is conducted in Mainland China/overseas, can I apply for the Technology Voucher Program funding?",
        tvp76: "No, both situations do not qualify for the application.",
        tvp77: "I have two companies under my name, and one of them has successfully applied for the Technology Voucher Program funding. Can I apply for the same funding for the other company?",
        tvp78: "Yes, each company can be approved for a maximum of 6 projects.",
        tvp79: "If I have already applied for other government funding for a technology project, can I still apply for the Technology Voucher Program funding?",
        tvp80: "No, the same project can only receive one local government funding.",
        tvp81: "If I need to increase the total cost of a project during its implementation, can I apply to increase the approved funding amount?",
        tvp82: "No, any requests to modify the project scope (i.e., approved technology plan), increase the approved total project cost, or increase the total amount of funding from the Innovation and Technology Fund will not be accepted.",
        tvp83: "What are the requirements for hiring service providers?",
        tvp84: "You need to seek external technology consultants/service providers to provide technical services or solutions for the project to meet the funding requirements, including procurement of equipment/hardware/software services related to the project.",
        tvp85: "How many supplier quotations do I need to provide for the Technology Voucher Program application?",
        tvp86: "You can provide multiple supplier quotations, but each company can be approved for a maximum of 6 projects.",
        tvp87: "How many supplier quotations do I need to provide for the Technology Voucher Program application?",
        tvp88: "Project value",
        tvp89: "Number of supplier quotations",
        tvp90: "Not exceeding HKD 50,000",
        tvp91: "Minimum of 2",
        tvp92: "Exceeding HKD 50,000 but not exceeding HKD 300,000",
        tvp93: "Minimum of 3",
        tvp94: "Exceeding HKD 300,000 but not exceeding HKD 1,400,000",
        tvp95: "Minimum of 5",
        tvp96: "Can I use an existing company account to apply for the first installment of the Technology Voucher Program funding?",
        tvp97: "No, you must open a new company account specifically for handling the income and expenses related to the project.",
        tvp98: "Will general office equipment, professional service fees, or administrative expenses be included in the funding?",
        tvp99: "No, the following are examples of expenses that are not included in the funding:",
        tvp100: "Building rent",
        tvp101: "Employee salaries and other related expenses, including but not limited to mandatory provident fund contributions, contract gratuities, annual salary adjustments, general fringe benefits (such as medical benefits), allowances (such as housing, transportation, overtime allowances), and general training and development.",
        tvp102: "General office equipment/hardware, software, and technology services or solutions required for daily operations.",
        tvp103: "Maintenance, warranty, and insurance for existing and newly acquired equipment.",
        tvp104: "Professional service fees unrelated to technology.",
        tvp105: "Marketing and branding expenses.",
        tvp106: "Transportation and accommodation.",
        tvp107: "Financing expenses (such as loan interest payments).",
        tvp108: "Can I pay for project expenses in cash?",
        tvp109: "Yes, but each transaction is limited to HKD 5,000 or below. Other non-cash payment methods, such as credit cards, checks, and bank transfers, do not have specific amount limits.",
        tvp110:"Consultation now",
        tvp111:"Q : ",
        tvp112:"A : ",
        url:require('@/assets/img/tvp-2-en.png'),
    },
    edit: {
        "edit01": "Customized Development",
        "edit02": "Perfectly Presenting Every Customer's Need",
        "edit03": "Templates are the same, but every business has its unique expertise.",
        "edit04": "Customized development, delving deep into the client's business characteristics and real needs. Technology + ingenuity, finely crafted, making your business stand out on the internet.",
        "edit05": "20 Years of Experience",
        "edit06": "Mature Website Planning Strategy and Development Technology",
        "edit07": "1-on-1 Service",
        "edit08": "Elite Engineer Team from China and Hong Kong, Rapid Response Throughout the Process",
        "edit09": "Serving Global Clients",
        "edit10": "Alignment with Promotion Rules in Overseas, Hong Kong, and Mainland China",
        "edit11": "One-Stop Service",
        "edit12": "Solve Development Fragmentation Processes, Service All in One Go",
        "edit13": "Tailored Development",
        "edit14": "Exclusive Design, Significantly Enhancing Brand Image",
        "edit15": "System Development",
        "edit16": "Membership/Project/E-commerce/OA/ERP/CRM/Logistics and Other Systems",
        "edit17": "Various Software Development, Customized According to Client Needs",
        "edit18": "Website Development",
        "edit19": "Corporate Portals, Foreign Trade Websites, E-commerce Websites, Websites for Various Industries",
        "edit20": "Adaptive Websites, Multi-Platform Adaptation; Multilingual, Global Deployment",
        "edit21": "APP Development",
        "edit22": "Android/iOS Native/Hybrid/Cross-Platform APP Development",
        "edit23": "Meeting Business Needs for Education, E-commerce, Healthcare, Automotive, and All Industries' APP Development",
        "edit24": "WeChat Development",
        "edit25": "H5 Pages/WeChat Official Accounts/Services/Mini Programs, Custom Development of Various WeChat Applications",
        "edit26": "Combining User Social, Reading, and Life Scenes, Enjoying WeChat's 1 Billion Traffic, Easily Accessing Opportunities",
        "edit27": "Elite Engineer Team from China and Hong Kong, 1-on-1 Intimate Service",
        "edit28": "China Sky With over 20 years of domestic and international website building experience, we have established an experienced elite engineer team in China and Hong Kong",
        "edit29": "Full 1-on-1 service, dedicated personnel liaison, ensuring timely communication, excellent technical skills, efficiently solving needs",
        "edit30": "100% Aligned with Customer Needs",
        "edit31": "Customized Website Development",
        "edit32": "Adaptive, Multilingual, Global Deployment, Tailored and Optimized According to Customer Characteristics and Needs",
        "edit33": "Our Website Customization Services",
        "edit34": "Tailored according to the company's characteristics and needs",
        "edit35": "Custom development of a website with functions, content, and design more aligned with the company's image and business needs",
        "edit36": "Better showcasing of the company's products and services, optimizing user experience, highlighting brand image, enhancing corporate competitiveness",
        "edit37": "Flexible and Controllable Development Process and Costs",
        "edit38": "1-on-1 full liaison, development process flexibly adjusted according to company needs, achieving the best solution for cost and time",
        "edit39": "Maximization of Performance and User Experience",
        "edit40": "Tailored optimization for website security and performance",
        "edit41": "Faster website operation speed, higher stability, better security",
        "edit42": "Design based on website target users' habits",
        "edit43": "Easier operation, a friendlier interface, quickly establishing trust with users",
        "edit44": "Customized UI/UX Design",
        "edit45": "User Experience (UX) and User Interface (UI) design that showcases the website's creativity, functionality, and ideas. Carefully designed UI and UX can demonstrate a company's advantages, leaving a better first impression on users, retaining potential customers, and creating more possibilities.",
        "edit46": "Multi-platform Adaptive Websites",
        "edit47": "Adaptive websites use flexible layouts that automatically adjust the website's appearance and layout based on the screen size and resolution of the visiting device. Multi-platform adaptation, flexible display, providing users with a good cross-device usage experience, enhancing website accessibility, increasing user engagement and interactivity, and being more conducive to search engine optimization.",
        "edit48": "Multilingual Global Deployment",
        "edit49": "Allowing website visitors to enjoy a fluent and intuitive user experience regardless of language and geographical location. A multi-language website tailored for you, creating an exclusive global gateway for your business, helping your company stand out among global peers and gaining favor from global users.",
        "edit50": "Development Tools",
        "edit51": "Websites Custom Designed for You",
        "edit52": "We tailor design every detail, committed to designing websites that best fit your unique characteristics. Customized UI/UX design, multi-platform adaptive websites, multilingual global deployment, empowering your business with a competitive edge and increased efficiency in the digital age.\n",
        "edit53": "One-stop Customized Solution",
        "edit54": "APP Development",
        "edit55": "Using mobile phones as carriers, expanding the market, creating new marketing opportunities, and tapping into huge business potential",
        "edit56": "Advantages of Custom APP Development",
        "edit57": "Mobile phones have become an indispensable part of people's lives. In the era of mobile internet, people's consumption habits are gradually shifting from offline to online. Customizing a dedicated APP facilitates tapping into the massive potential of the mobile market, making the company and brand more deeply ingrained in people's minds.",
        "edit58": "Mobile APPs for education, e-commerce, healthcare, automotive, and various industries help companies save on manpower expenses, increase revenue sources, and strengthen connections with customers.",
        "edit59": "Choose the App Type That Suits You Better",
        "edit60": "In 20 years, China Sky Technology has served hundreds of companies, customizing exclusive APPs for different industries.",
        "edit61": "We provide services like E-commerce APPs, Inventory APPs, MRP APPs, CRM APPs, OA APPs, Ordering APPs, Reservation APPs, and other customized APP services tailored to your enterprise and brand characteristics.",
        "edit62": "Development Process",
        "edit63": "One-stop service, full 1-on-1 dedicated personnel liaison, continuously adjusting development plans and processes according to actual needs, ensuring cost control, transparency in the process, saving time and worries.",
        "edit64": "Requirement Communication",
        "edit65": "Quotation",
        "edit66": "Interface Design",
        "edit67": "Technical Development",
        "edit68": "Testing",
        "edit69": "Deployment",
        "edit70": "Post-launch Maintenance",
        "edit71": "Custom WeChat Application Development",
        "edit72": "Customized Development of WeChat Mini Programs, H5 Pages, Official Accounts, Services, Multiple WeChat Application Custom Development,\n" +
                   "1 Billion+ Daily Active Users on WeChat, 300 Million+ Daily Active Users on Mini Programs",
        "edit73": "Ride the high-traffic social media, easily access the Chinese mainland market",
        "edit74": "No need for downloads, low memory usage, reducing usage thresholds",
        "edit75": "Leveraging WeChat's ecosystem, social fusion effect, easily reaching target users",
        "edit76": "Scan-to-use, high conversion rate, reducing customer acquisition costs for enterprises",
        "edit77": "Leveraging official accounts, video accounts, building content ecology, enhancing corporate image",
        "edit78": "Mini Program Custom Development",
        "edit79": "WeChat Mini Websites, WeChat Stores, O2O Platforms, WeChat Takeout, WeChat Real Estate, WeChat Live Streaming",
        "edit80": "One-stop service, elite engineers providing full 1-on-1 liaison, tailored exclusive mini-programs based on enterprise needs",
        "edit81": "20 years of technical accumulation by CHINA SKY TECHNOLOGY, providing personalized high-quality customized services, ensuring continuous security",
        "edit82": "WeChat Official Account Operation",
        "edit83": "High-quality content marketing, covering resources across all industries, rapidly spreading brand image, enhancing influence",
        "edit84": "Viral traffic acquisition, all-channel advertising promotion, building private domain matrix",
        "edit85": "Building micro-stores + customer service systems, precise marketing, sustained performance growth",
        "edit86": "Community building, establishing clear user profiles, promoting customer influx",
        "edit87": "Industry Opportunities for Mini Programs"
    },
    about: {
        "about01": "China Sky Technology",
        "about02": "Website Development / APP Development / Mini Program Development / System Development / Enterprise IT Operations",
        "about03": "High-quality and Innovative Technology and Services",
        "about04": "Empowering China-Hong Kong Enterprises Digitally and Globally",
        "about05": "Core Values",
        "about06": "Technology",
        "about07": "Our Capital",
        "about08": "Using the most advanced technology to ensure efficiency and reliability",
        "about09": "Quality",
        "about10": "Our Mission",
        "about11": "Strictly control the development process to meet the highest development standards",
        "about13": "Service",
        "about14": "Our Aim",
        "about15": "Comprehensive customer service, quick response, professional technical support, and after-sales service",
        "about16": "Innovation",
        "about17": "Our Goal",
        "about18": "Keep up with the forefront of technology, constantly break through, and develop new products and solutions",
        "about19": "Our Services",
        "about20": "IT Support Services",
        "about21": "Network and System Maintenance, reducing enterprise costs and improving efficiency",
        "about22": "Custom Development",
        "about23": "100% align with customer requirements, 1-on-1 dedicated personnel liaison",
        "about24": "Online Stores",
        "about25": "Gain sales opportunities, increase sales channels, reach potential customers",
        "about26": "System Development",
        "about27": "Tailored according to enterprise characteristics, align with enterprise needs, accurately improve efficiency",
        "about28": "Website Development",
        "about29": "Showcase corporate image, communicate brand culture, enhance brand visibility",
        "about30": "APP Development",
        "about31": "Tap into the huge potential of the mobile market, make brand image deeply rooted in people's minds",
        "about32": "WeChat Development",
        "about33": "WeChat application development + content operations, leveraging social media for business exponential growth",
        "about34": "Watch video",
        "about35": "https://www.youtube.com/embed/WBLueQUHxiQ",
    },
    dtspp:{
        dtspp1:'What is the Digital Transformation Support Pilot Program (DTSPP)? ',
        dtspp2: 'The Digital Transformation Support Pilot Scheme (hereinafter referred to as the "Scheme") will provide funding to local small and medium-sized enterprises (hereinafter referred to as "SMEs") on a one-to-one (1:1) matching basis to assist SMEs Apply ready-to-use basic digital technology solutions. The Government of the Hong Kong Special Administrative Region (hereinafter referred to as the "Government") has set aside HK$500 million for matching purposes. ' ,
        dtspp3: 'SMEs applying for funding support under this program must belong to the catering industry or retail industry (excluding catering industry), and must be registered in Hong Kong in accordance with the Business Registration Ordinance (Chapter 310) and have substantial business operations in Hong Kong . They must not be listed companies, statutory bodies or non-governmental organizations receiving public funding. ',
        dtspp4: 'Hong Kong Cyberport Management Company Limited (hereinafter referred to as "HKCMCL") is the management organization of this project. If you have any questions about these guidelines or the plan, please contact HKCMCL through the channels listed at ',
        dtspp5: '',

        dtspp6: 'target',
        dtspp7: 'This program aims to achieve the goal of accelerating the digital transformation of SMEs by providing one-to-one matching grant funding to support them in adopting pre-evaluated, ready-to-use digital technology solutions in target solution categories. . ',
        dtspp8: 'The program categories of the plan goals are as follows:',
        dtspp9: 'Electronic payment system and store sales',
        dtspp10: 'Assist enterprises to automate payment collection and calculation workflows, and support electronic payments to develop new payment channels. This type of system can also include self-service store sales solutions, such as self-service ordering systems and self-service vending machines. The system can automatically generate reports through marketing data, allowing companies to analyze their business conditions and provide a basis for business decisions. ',
        dtspp11: 'Online promotion',
        dtspp12: 'Build company websites and social media pages for companies, or help them promote their business through online search engines and social media. ',
        dtspp13: 'Customer management and discount system',
        dtspp14: 'This type of system can support sales promotion activities such as electronic membership programs and electronic coupons. The system can also provide customer support, case management and knowledge base functions, and can provide reports to allow companies to effectively review, analyze and manage sales activities, targets, potential customers discovered and related follow-up work, allowing companies to keep pace with existing Increase interactions with potential customers and centrally store customer information and contact records. ',
        dtspp15: 'Funding scope',
        dtspp16: 'If equipment, hardware, software and any other assets form a significant part of the solution portfolio, funding is allowed for purchase, rental or subscription, subject to the following rules:',
        dtspp17: 'Title and interest in new equipment, hardware, software and any other assets purchased with funding from this program shall belong to the applicant;',
        dtspp18: 'All items purchased, purchased or leased with funding from this program shall be licensed products and shall not infringe the intellectual property rights of any third party; and',
        dtspp19: 'The risk of use of the equipment, hardware, software and any other assets will be borne by and retained by the applicant. ',
        dtspp20: 'The scheme of an approved application should be implemented and ready for the applicant\'s use within 9 months of the application being approved. For subscription-based programs, grant funding is allowed for up to two years during the subscription period. ',
        dtspp21: 'Subsidy amount',
        dtspp22: 'Each eligible applicant will be provided with a subsidy of up to HK$50,000 on a one-to-one basis based on the cost of the projects listed in the project list. Applicants must contribute at least half of the total cost of the adopted program. ',
        dtspp23: 'In-kind donations from applicants or any non-monetary consideration will not be accepted. ',
        dtspp24: 'Approved application projects (including but not limited to the applicant or projects funded by this plan) may not receive funding from other local public funding sources',
        dtspp25: 'Application procedure',
        dtspp26: 'This program will open applications to applicants in batches, and each application batch will be about a designated industry. In subsequent batches of applications, new digital technology solutions and program categories will be added to the program list to provide applicants with richer choices. ',
        dtspp27: 'For the timetable of each application batch and the types of digital technology solutions available for selection, please see',
        dtspp28: 'Applicants who have submitted an application and whose application is still pending approval may not submit another application until their originally submitted application has been rejected or withdrawn. ',
        dtspp29: 'Applicants whose applications have been approved are not allowed to apply again. ',
        dtspp30: 'Before submitting an application, applicants must register an applicant account on the program\'s website. Applicants can identify the solution they want to adopt in the solution list and contact the solution provider directly to understand the capabilities of the solution and determine whether it meets their requirements. Applicants must click the "Inquiry" button on the relevant solution page to use the program\'s website to confirm with the solution provider whether the solution package can be provided to the applicant. After receiving confirmation from the solution provider, the applicant can proceed to complete the online application form. ',
        dtspp31: 'Applicants are advised to understand and compare different solutions and solution providers before choosing the one they wish to adopt. Only one package can be selected per application. ',
        dtspp32: 'Please note that being included in the solution list does not mean that any solution or solution provider is recognized by the government or HKCMCL. ',
        dtspp33: 'Applicants must submit their applications through the program\'s website. Applications submitted through other methods (including but not limited to email or post) will not be accepted. ',
        dtspp34: 'Application qualification',
        dtspp35: 'Registered in Hong Kong under the Business Registration Ordinance (Chapter 310);',
        dtspp36: 'Not a Hong Kong listed company, statutory body or non-governmental organization receiving public funds;',
        dtspp37: 'Have substantial business operations in the following industries ("designated industries") in Hong Kong at the time of application:',
        dtspp38: 'Catering industry',
        dtspp39: 'Retail industry (excluding catering industry);',
        dtspp40: 'Fewer than 50 full-time employees',
        dtspp41: 'Excluding part-time employees',
        dtspp42: 'For companies registered with different companies but held under the same parent company, all full-time employees within the company group will be counted and should be less than 50 for applicants to be eligible to participate in this program',
        dtspp43: 'Applicants whose applications have been approved or whose applications are pending approval are not allowed to submit further applications',
        dtspp44: 'Information required during submission',
        dtspp45: 'Basic company information:',
        dtspp46: 'Company name',
        dtspp47: 'Company address',
        dtspp48: 'Business registration certificate number',
        dtspp49: 'Industry Category',
        dtspp50: 'Main contact information (name, mobile phone number, email address)',
        dtspp51: 'Selected plan',
        dtspp52: 'Certification documents (commercial certification documents)',
        dtspp53: 'Submit file',
        dtspp54: 'Business Proof',
        dtspp55: 'Application Timetable - Funding Applicant (SME)',
        dtspp56: 'Program category',
        dtspp57: 'Application date accepted',
        dtspp58: 'Deadline',
        dtspp59: 'Batch #1 (Catering Industry Plan Application)',
        dtspp60: 'Optional program categories for funding applicants:',
        dtspp61: 'Basic:',
        dtspp62: 'Electronic payment system and store sales',
        dtspp63: '(optional)',
        dtspp64: 'Online promotion',
        dtspp65: 'Customer management and discount system',
        dtspp66: 'Coming Soon',
        dtspp67: 'Batch #2 (Retail Industry Program Application)',
        dtspp68: 'Remarks:',
        dtspp69: 'Applications submitted after the deadline will not be accepted. ',
        dtspp70: 'The application start time is 12 noon Hong Kong time on the date each batch of applications is accepted. ',
        dtspp71: 'The application deadline is 11:59 pm Hong Kong time on the application deadline for each batch. ',
        dtspp72: 'Application procedure',
        dtspp73: 'step',
        dtspp74: 'Funding grants to solution providers',
        dtspp75: 'Amount (% of approved amount)',
        dtspp76: 'condition',
        dtspp77: 'stage',
        dtspp78: 'After the applicant confirms that the solution has been implemented and provides satisfactory evidence (such as a receipt from the solution supplier) that it has paid its share of the fees for the solution used on the website of this plan, the solution provider will be Pay a down payment of 50% of the approved grant funding. ',
        dtspp79: 'Twelve months after the application is approved, the funding applicant must submit a summary report and relevant supporting documents and the remaining funds will be released to the solution provider only after being duly accepted by HKCMCL. ',
        dtspp80: 'Business Registration Certificate',
        dtspp81: 'Business Registration Certificate Valid Business Registration Certificate (Note: The company name on the Business Registration Certificate must match the company name specified on the registration submission materials)',
        dtspp82: 'Company registration certificate',
        dtspp83: 'Company Registration Certificate (Note: The company name on the Company Registration Certificate must match the name of the organization listed in the application)',
        dtspp84: 'statement',
        dtspp85: 'Applicant\'s statement signed by the company holder/director and stamped with company seal',
        dtspp86: 'Store photo',
        dtspp87: 'Show photos of stores with large and substantial business at the specified address',
        dtspp88: 'Proof of address',
        dtspp89: '<p>One of the following documents proving that the applicant conducts substantial business at the address specified in the application:</p>'
            +'<ul><li>Electricity bill for the past 3 months; or </li><li>Water bill for the past 2 quarters; or <li>Copy of lease agreement (tenant name must match the one on the application The company name is the same) </li></ul>',
        dtspp90: 'Eligible Food and Environmental Hygiene Department License',
        dtspp91: '<p>*For catering businesses only</p> Provide a copy of one of the eligible Food and Environmental Hygiene Department licenses',
        dtspp92: 'Register a grant applicant account',
        dtspp93: 'Select a plan from the website',
        dtspp94: 'Contact solution provider and select solution',
        dtspp95: 'The solution provider indicates "intention to provide" in the portal',
        dtspp96: 'Submit application online',
        dtspp97: 'Check eligibility and completeness',
        dtspp98: 'Submit to the review committee for approval',
        dtspp99: 'Approve the applicant to place an order with the supplier',
        dtspp100: 'Approve the applicant to place an order with the solution supplier',
        dtspp101: 'SMEs confirm solution delivery in the portal',
        dtspp102: 'Certificate document approval',
        dtspp103: 'Release the first grant (50%)',
        dtspp104: 'Small and medium-sized enterprises submit summary reports on the portal',
        dtspp105: 'Check the completeness of the report and supporting documentation',
        dtspp106: 'Release the second grant'
    },
    cloud:{
        cloud01:'Device Protect365 -',
        cloud0101:'Cloud Backup Reinvented',
        cloud02:'“This will disrupt the Cloud Industry – The first of its kind anywhere with unlimited licences included to backup both Devices and SaaS Services including',
        cloud03:'Servers, Endpoints, Databases, VMware, Hyper-V, Synology, QNAP, Dropbox, Google Drive and Microsoft 365 in an all-in-one solution.”',
        cloud04:'Saaher Muzafer, CEO of Backup Everything.',
        cloud05:'Email Report',
        cloud06:'Daily Notifications',
        cloud07:'Support',
        cloud08:'Free and Included',
        cloud09:'Free Licences',
        cloud10:'Nothing To Pay',
        cloud11:'Storage',
        cloud12:'Multiple Regions',
        cloud13:'Introducing Device Protect365',
        cloud14:'Device Protect365 is an all-encompassing cloud backup solution designed to secure your data with automatic and incremental backups allowing you to have that piece of mind that should a disaster happen, you will be able to restore your data anytime. The added benefit is we give free licenses so you can backup unlimited users and machines plus we give the flexibility for you to choose where you want to store your data.',
        cloud16:'Backup Physical and Virtual Servers',
        cloud17:'Protect your Endpoints for Windows and Mac',
        cloud18:'Backup Databases, Synology and QNAP',
        cloud19:'Backup Microsoft 365, Google Drive and Dropbox',
        cloud20:'Backup Buckets from AWS, Azure, Google CP and more',
        cloud21:'Unlimited Licenses Included',
        cloud22:'Scheduled Automatic and Incremental Backups',
        cloud23:'Quick and Easy Recovery',
        cloud24:'Files & Folders',
        cloud25:'Windows',
        cloud26:'Mac',
        cloud27:'Linux',
        cloud28:'Windows System Backup',
        cloud29:'MS SQL',
        cloud30:'My SQL',
        cloud31:'Synology',
        cloud32:'Hyper-V',
        cloud33:'VMware',
        cloud34:'Oracle DB',
        cloud35:'Maria DB',
        cloud36:'QNAP',
        cloud37:'MS Exchange',
        cloud38:'MS Exchange (Granular)',
        cloud39:'M365 - Exchange',
        cloud40:'M365 - SharePoint',
        cloud41:'M365 - OneDrive',
        cloud42:'M365 - Teams',
        cloud43:'M365 - Groups',
        cloud44:'Wasabi',
        cloud45:'AWS',
        cloud46:'Azure',
        cloud47:'Google Cloud',
        cloud48:'Backblaze',
        cloud49:'Rackspace',
        cloud50:'Google Drive',
        cloud51:'Dropbox',
        cloud52:'FTP',
        cloud53:'SFTP',
        cloud54:'Easy Data Recovery',
        cloud55:'Quickly and Easily Restore your Data with our User-Friendly Interface',
        cloud56:'Scale As You Grow',
        cloud57:'Add, Remove or Scale Up your Backup Plan as you Grow',
        cloud58:'Customer Support',
        cloud59:'We are to here to help so that your backups never remain uninterrupted. ',
        cloud60:'Automated Backup',
        cloud61:'Automatic & Regular Backups to Protect against Data Loss',
        cloud62:'Multiple Storage Locations',
        cloud63:'Choose to store your data offsite in multiple regions or locally',
        cloud64:'Leading Encryption',
        cloud65:'256 AES Encryption to Protect your Data at flight and at rest',
        cloud66:'What can you Backup to our Cloud and Locally?',
        cloud67:'Security & Compliance',
        cloud68:'Our solution is built on the latest coding, security, and compliance protocols,\n' +
            'so rest assured your data is securely stored with no 3rd party access.',
        cloud69:'Frequently Asked Questions',
        cloud70:'Q: What makes Device Protect365 different from other providers?',
        cloud71:'In simple terms, model means no licence costs and only on storage on a per TB basis. ',
        cloud72:'Q: How secure is my data with your backup solutions?',
        cloud73:'We use industry-leading security protocols to ensure that your data is safe and secure, both in transit and at rest. Our data centers are located in secure facilities with 24/7 monitoring, and we use encryption to protect your data against cyber threats.',
        cloud74:'Q: Is it easy to set up and manage all the solutions?',
        cloud75:'Yes! Our backup solutions are designed to be user-friendly, with simple and intuitive interfaces that require no special technical knowledge. Plus, we offer customer support to help you with any questions or issues you may have.',
        cloud76:'Q: Can your backup solutions scale with my business?',
        cloud77:'Yes! Our cloud backup solutions are designed to grow with your business, so you can easily add or remove data as needed. Plus, our flexible pricing options make it easy to scale up or down as your business evolves.',
        cloud78:'Q: How often does your solutions back up my data?',
        cloud79:'You can customize your backup schedule to fit the needs of your business. Choose how often you want your data backed up and at what time.',
        cloud80:'Q: How quickly can I recover my data in the event of a disaster or data loss?',
        cloud81:'Our user-friendly interface makes it easy to recover your data quickly and easily in the event of a disaster or data loss. For the device solutions its always best to create a local copy so you can restore from LAN speed also.',
        cloud82:'Q: What platforms and operating systems do you support?',
        cloud83:'We can backup a variety of platforms and operating systems, including Windows, macOS, and Linux. Plus also MS SQL, MySQL, VMware, Hyper-V, Synology and QNAP. ',
    }


}