<template>

  <div class="">
      <div class="contactUsBox"  data-aos="fade-up" data-aos-duration="1500">
        <h2>{{ $t("form.titles") }}</h2>
        <p>{{ $t("form.titleText") }}</p>
        <div class="contactInputDiv">
          <div class="inputItem">
            <label><span>*</span> {{ $t("form.name") }}</label>
            <div>
              <input type="text" :placeholder='$t("form.placeholderName")' v-model="aboutName" @blur="blurName"/>
              <p>{{name}}</p>
            </div>

          </div>
          <div class="inputItem">
            <label><span>*</span> {{ $t("form.tel") }}</label>
            <div>
              <input type="tel" :placeholder='$t("form.placeholderTel")' v-model="aboutPhone" oninput="value=value.replace(/[^\d]/g, '')" @blur="blurPhone"/>
              <p>{{tel}}</p>
            </div>
          </div>
          <div class="inputItem">
            <label><span>*</span> {{ $t("form.mail") }}</label>
            <div>
              <input type="email" :placeholder='$t("form.placeholderEmail")' v-model="aboutEmail" @blur="blurEmail"/>
              <p>{{email}}</p>
            </div>
          </div>
          <div class="inputItem">
            <label>{{ $t("form.message") }}</label>
            <div>
              <textarea :placeholder='$t("form.placeholderText")' ref="textarea" v-model="aboutMessage"></textarea>
              <p style="font-size: 14px; color: #999;margin-top:5px; bottom: inherit"><span style="color: #f00">*</span>{{ $t("form.tips") }}</p>
            </div>
          </div>
          <div class="inputItem">
            <label></label>
            <div class="contactSubmit">
              <button @click="aboutSubmit" class="btn btn13">
                <span class="btn-inner"> {{ $t("form.submit") }}</span>
                <div class="bgsqr1"></div>
                <div class="bgsqr2"></div>
              </button>
            </div>
          </div>
        </div>
      </div>

    <!--驗證-彈窗-->
    <Vcode :show="isShow" @success="onSuccess" @close="onClose"
      :successText="$t('form.successText')" :sliderText="$t('form.sliderText')" :failText="$t('form.failText')"
    />

  </div>

  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>


</template>

<script>
import axios from 'axios'
import { contentService } from "@/network/main";
import Vcode from "vue3-puzzle-vcode";
import AOS from "aos";
export default {
  name: "requestForm",
  data() {
    return {
      // 联络我们
      aboutName: '',
      aboutEmail: '',
      aboutPhone: '',
      aboutMessage: '',
      //提示语
      name: '',
      email: '',
      tel: '',
      // 驗證
      isShow: false,
      isPopupVisible: false,
      popupText: '',
    }
  },
  components:{
    Vcode
  },

  methods:{
    onSuccess() {
      const that = this;
      that.isShow = false; // 隱藏验证
      const ttoclid = window.ET.getClid();//seo新增ttoclid
      const from = this.$route.query.from;
      let params = {
        "name": that.aboutName,
        "email": that.aboutEmail,
        "tel": that.aboutPhone,
        "message": that.aboutMessage,
        "ttoclid": ttoclid
      };
      if (from === 'mall') {
        params.store = this.$t("form.online");
      } else if (this.type_name !== '') {
        params.store = this.store;
      }

      contentService(params).then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.isPopupVisible = true
          this.popupText="發送成功"
          setTimeout(() => {
            this.isPopupVisible = false;
            const targetPath = `/hk/thankYou`;
            window.location.href = targetPath;
          }, 2000);
        }
      })

      //seo接口
      axios.post('https://lead-service.cs.topkee.com/v1/lead/collect/potential/d5f90978eb684166b0cc8d4db9d69601', params, {
      }).then(res => {
        if (res.data.code == 200) {
         console.log(res)
        }
      }).catch(err => {
        console.log(err);
      });

    },
    aboutSubmit(){
      let that = this

      that.blurName()
      that.blurPhone()
      that.blurEmail()

      if (that.name === "" && that.tel === "" && that.email === ""){
        that.isShow = true
      }
    },
    // 驗證
    blurName() {
      if (this.aboutName === "") {
        this.name = this.$t("form.placeholderName")
      } else {
        this.name = ""
      }
    },
    blurPhone() {
      if (this.aboutPhone === "") {
        this.tel = this.$t("form.placeholderTel")
      } else {
        this.tel = ""
      }
    },
    blurEmail() {
      let verifyEmail = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (this.aboutEmail === "") {
        this.email = this.$t("form.placeholderEmail")
      } else if (!verifyEmail.test(this.aboutEmail)) {
        this.email = this.$t("form.placeholderEmail2")
      } else {
        this.email = ""
      }
    },
    // 用户点击遮罩层，应该关闭模态框
    onClose() {
      this.isShow = false;
    },

  },
  mounted() {
    AOS.init({ });
  }
}
</script>

<style scoped>
@import "../assets/css/contactUs.css";
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.8);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
  white-space: pre-line;
  line-height: 2;
}
</style>